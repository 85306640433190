import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { BASE_BREADCRUMBS } from "../constants"
import "../page-styles/resources.scss"

const ResourcesPage = ({ data }) => {
  let resources = data?.allResourcesJson?.nodes?.slice() || []
  let listLength = Math.ceil(resources.length / 2)
  let col1 = resources.splice(0, listLength)
  let col2 = resources.splice(-listLength)
  return (
    <Layout
      pageTitle="Resources"
      breadcrumbs={[...BASE_BREADCRUMBS, { url: "/resources", name: "Resources" }]}
      banners={{
        data: [
          {
            image: "https://res.cloudinary.com/dthskrjhy/image/upload/v1499977187/images/resource-header.jpg"
          }
        ],
        name: "Resources banner",
      }}
    >
      <Seo
        title="Resources | Typenex Medical"
        description="Resources for Typenex Medical"
      />
      <div className="row page-mb">
        {
          [col1, col2].map((column, ind) => {
            return (
              <div className="col-sm-6" key={ind}>
                <ul className="mb-0">
                  {
                    column?.map((resource, i) => {
                      return (
                        <li key={i}>
                          <a href={resource.url}>{resource.label}</a>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            )
          })
        }
      </div>
    </Layout>
  )
}

export default ResourcesPage

export const query = graphql`
 query {
   allResourcesJson {
     nodes {
       url
       label
     }
   }
 }
`
